.checklist {
  ul {
    li {
      padding-left: 34px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        background-image: url("/dist/images/icons/checklist-yes.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
      }
    }
  }

  &--no {
    ul {
      li {
        &:before {
          background-image: url("/dist/images/icons/checklist-no.svg");
          border-radius: 0;
        }
      }
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .checklist {
    ul {
      li {
        padding-left: 40px;

        &:before {
          width: 28px;
          height: 28px;
          top: -1px;
        }
      }
    }

    &--no {
      ul {
        li {
          &:before {
            width: 30px;
          }
        }
      }
    }
  }
}