.jump-links {
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);

  &__list {
    li {
      padding-left: 0;

      &:before {
        content: none;
      }
    }
  }

  .icon-link {
    @include margin-spacer(5, top);
    &:not(:last-child) {
      @include margin-spacer(5, bottom);
    }
  }
}

.matrix:last-child .jump-links__list {
  margin-bottom: 0;
}
