.matrix--spotlight {
  background-color: $cloud-white;
}

.spotlight {
  @include padding-spacer(12, top);
  @include padding-spacer(12, bottom);
  display: flex;
  flex-direction: column-reverse;

  &__image {
    display: block;
    position: relative;
    width: 100%;
    height: calc(100vw - 32px);
    max-width: 32rem;
    max-height: 32rem;
    margin-bottom: 2.8rem;
    flex-shrink: 0;
    flex-grow: 0;
  
    &--photo {
      margin-top: -2rem;
      margin-bottom: .8rem;
  
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url("/dist/images/graphics/shape-7-border.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  &__photo, &__svg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    flex-grow: 1;
  
    h2 {
      @include margin-spacer(4, bottom);
    }
  
    h3 {
      font-weight: $font-weight--bold;
      margin-top: 0;
      @include margin-spacer(5, bottom);
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex-direction: column-reverse;
    align-items: flex-start;
  
    &__image {
      margin-bottom: 3.2rem;
  
      &--photo {
        margin-top: -2rem;
        margin-bottom: 1.2rem;
      }
    }
  }
  
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  
    &__image {
      margin-left: 6.4rem;
      margin-bottom: 0;
    }
  }
  
  @media screen and (min-width: map-get($breakpoints, xl)) {
    &__image {
      max-width: 44.8rem;
      max-height: 44.8rem;
      margin-left: 10.4rem;
  
      &--photo {
        margin-top: -3rem;
        margin-bottom: -3rem;
      }
    }
  }
}