.matrix--home-cta-photo {
  @include margin-spacer(14, top);
  @include margin-spacer(14, bottom);
}

.home-cta-photo {
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-cta-photo__shape {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 32rem;
  margin-bottom: 2.8rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0px;
    background-image: url("/dist/images/graphics/shape-1-border.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 100%;
  }
}

.home-cta-photo__photo {
  display: block;
  width: 100%;
}

.home-cta-photo__content {
  h2 {
    @include margin-spacer(4, bottom);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .home-cta-photo {
    align-items: flex-start;
  }

  .home-cta-photo__shape {
    margin-bottom: 3.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .home-cta-photo {
    flex-direction: row;
    align-items: center;
  }

  .home-cta-photo__shape {
    margin-right: 6.4rem;
    margin-bottom: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .home-cta-photo__shape {
    max-width: 44.8rem;
    margin-right: 10.4rem;
  }
}