html {
  font-size: 62.5%;
}

body {
  font-family: $base__font-family;
  color: $base__font-colour;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;
  font-weight: $base__font-weight;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

h1,
.h1,
.h-title {
  font-family: $heading__font-family;
  color: $h1__colour;
  font-weight: $h1__font-weight;
  font-size: $h1__font-size--mobile;
  line-height: $h1__line-height--mobile;
  letter-spacing: $h1__letter-spacing--mobile;
  @include margin-spacer(8, bottom);
}

.highlighted {
  line-height: 1.1;

  span {
    background-color: $cloud-white;
    color: $primary-colour;
    display: inline;
    padding: .3rem .6rem;
    box-decoration-break: clone;
  }
}

h2,
.h2,
.h-section {
  font-family: $heading__font-family;
  color: $h2__colour;
  font-weight: $h2__font-weight;
  font-size: $h2__font-size--mobile;
  line-height: $h2__line-height--mobile;
  @include margin-spacer(14, top);
  @include margin-spacer(8, bottom);
}

h3,
.h3,
.h-cross,
.h-md {
  font-family: $heading__font-family;
  color: $h3__colour;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
  @include margin-spacer(11, top);
  @include margin-spacer(7, bottom);
}

.h-para,
.h-sm {
  font-size: $h-para__font-size--mobile;
  line-height: $h-para__line-height--mobile;
  font-weight: $h-para__font-weight;
  @include margin-spacer(8, top);
  @include margin-spacer(2, bottom);
}

.matrix--text .h-para:last-child {
  @include margin-spacer(8, bottom);
}

p,
.p,
.paragraph {
  @include margin-spacer(8, bottom);

  .body-lg & {
    @include margin-spacer(9, bottom);
  }
}

b, strong {
  font-weight: $font-weight--bold;
}

.standfirst,
.body-lg {
  font-size: $stf__font-size--mobile;
  line-height: $stf__line-height--mobile;
}

.standfirst {
  color: $stf__colour;
}

.body-lg {
  color: $grey1;
}

.quotation {
  font-family: $heading__font-family;
  color: $quot__colour;
  font-size: $quot__font-size--mobile;
  line-height: $quot__line-height--mobile;
  font-weight: $quot__font-weight;
  @include margin-spacer(6, bottom);
  position: relative;
}

.caption {
  font-size: $body-sm__font-size--mobile;
  line-height: $body-sm__line-height--mobile;
  @include margin-spacer(8, bottom);
}

.body-sm {
  font-size: $body-sm__font-size--mobile;
  line-height: $body-sm__line-height--mobile;
}

a,
.inline-link {
  color: $link__colour;
  text-decoration: $link__text-decoration;
  text-decoration-thickness: $link__text-decoration-thickness;
  text-underline-offset: $link__text-underline-offset;
  transition: all $transition;

  &:hover {
    color: $link__hover-colour;
    text-decoration-thickness: $link__hover-text-decoration-thickness;
  }

  &.no-hover {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.inline-link {
  cursor: pointer;

  &:active {
    color: $accessibility__text !important;
    text-decoration: none !important;
    background-color: $accessibility__highlight;
    box-shadow: $accessibility__box-shadow;
  }
}

ul,
ol {
  @include list-reset();
  font-weight: $base__font-weight;
  @include margin-spacer(8, bottom);

  li {
    position: relative;
    padding-left: 24px;
    @include margin-spacer(4, bottom);
    @include last-child-no-bottom-margin;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 1px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    ol, ul {
      @include margin-spacer(4, top);
      @include margin-spacer(4, bottom);
    }

    ul li:before {
      width: 7px;
      height: 7px;
      top: 8px;
      border-radius: 0;
    }

    ol li:before {
      counter-increment: dots;
      content: counter(dots, lower-alpha) ")";
    }
  }
}

ul > li:before {
  display: block;
  width: 8px;
  height: 8px;
  top: 8px;
  background-color: $london-stone;
  border-radius: 100%;
}

ol {
  counter-reset: dots;

  > li:before {
    left: -1px;
    counter-increment: dots;
    content: counter(dots, decimal);
    font-weight: $font-weight--bold;
  }
}

// Tags
.tags {
  @include list-reset();
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  li {
    margin-bottom: 0;
  }
}

.tag {
  display: flex;
  color: $link__colour;
  font-weight: $font-weight--normal;
  padding: 5px 12px;
  margin: 4px;
  border-radius: 4px;
  border: 1px solid $link__colour;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-bottom: 4px;
  }

  &--invert {
    color: $cloud-white;
    border-color: $cloud-white;
  }
}

a.tag:hover {
  background-color: $tower-blue;
  border-color: $tower-blue;
  color: $cloud-white;
  cursor: pointer;
}

a.tag--invert {
  &:hover {
    background-color: $cloud-white;
    color: $link__colour;
  }
}

hr {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
  border: none;
  background-image: url("/dist/images/graphics/dot.svg");
  background-position: -2px top;
  background-size: 8px 3px;
  background-repeat: repeat-x;
}

.pale-green-background {
  background-color: $southwark-mint;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  body {
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;
  }

  h1,
  .h1 {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
    letter-spacing: $h1__letter-spacing--desktop;
  }

  .highlighted {
    font-size: 9rem;

    span {
      padding: 0.8rem 1.6rem;
    }
  }

  h2,
  .h2 {
    font-size: $h2__font-size--desktop;
    line-height: $h2__line-height--desktop;
  }

  h3,
  .h3,
  .h-md {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  .h-para,
  .h-sm {
    font-size: $h-para__font-size--desktop;
    line-height: $h-para__line-height--desktop;
  }

  .standfirst,
  .body-lg {
    font-size: $stf__font-size--desktop;
    line-height: $stf__line-height--desktop;
  }

  .quotation {
    font-size: $quot__font-size--desktop;
    line-height: $quot__line-height--desktop;
  }

  .caption,
  .body-sm {
    font-size: $body-sm__font-size--desktop;
    line-height: $body-sm__line-height--desktop;
  }

  ul,
  ol {
    li {
      padding-left: 30px;

      ul li:before {
        width: 9px;
        height: 9px;
        top: 10px;
      }
    }
  }

  ul li:before {
    width: 10px;
    height: 10px;
    top: 10px;
  }

  .tag {
    padding: 6px 16px;
  }
}