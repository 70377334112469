.matrix--data-table table {
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-size: $h-para__font-size--mobile;
  line-height: $h-para__line-height--mobile;
  font-weight: $font-weight--medium;

  &:last-child {
    padding-right: 0;
  }
}

td, th {
  @include padding-spacer(4, top);
  @include padding-spacer(4, bottom);
}

thead tr {
  vertical-align: bottom;
}

tbody tr {
  vertical-align: top;
}

/* Force table to not be like tables anymore */
table.rt-responsive-table.rt-vertical-table, table.rt-responsive-table.rt-vertical-table thead, table.rt-responsive-table.rt-vertical-table tbody, table.rt-responsive-table.rt-vertical-table th, table.rt-responsive-table.rt-vertical-table td, table.rt-responsive-table.rt-vertical-table tr {
	display: block;
	overflow: hidden;
}

/* Hide table headers (but not display: none;, for accessibility) */
table.rt-responsive-table.rt-vertical-table thead tr {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

table.rt-responsive-table.rt-vertical-table {
  display: flex;
  flex-direction: column;
  @include padding-spacer(4, top);
  border-top: 2px solid $grey1;

  tr:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  td:before {
    display: block;
    font-size: $h-para__font-size--mobile;
    line-height: $h-para__line-height--mobile;
    @include padding-spacer(2, bottom);
    font-weight: $font-weight--medium;
    border-bottom: 2px solid $grey2;
  }

  .responsive-container {
    padding: 16px 0 24px;
  }
}

// Vertical tables - used in form submission response templates
table.table--vertical-headings {
  @include margin-spacer(10, bottom);

  th {
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    width: 30%;
    min-width: 200px;
  }

  td {
    border: none;

    ul {
      margin: 0;
      padding: 0 0 0 15px;
      list-style: inside;
    }
  }

  th, td {
    @include padding-spacer(4, top);
    @include padding-spacer(4, bottom);
  }

  tr {
    border-bottom: 1px solid $grey2;

    &:last-child {
      border-bottom: 2px solid $grey2;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  table.rt-responsive-table.rt-vertical-table {
    td:before {
      font-size: $h-para__font-size--desktop;
      line-height: $h-para__line-height--desktop;
    }
  }
}

@media screen and (min-width: 689px) {
  table {
    border-top: 2px solid $grey1;
  }

  th {
    font-size: $h-para__font-size--desktop;
    line-height: $h-para__line-height--desktop;
  }

  td, th {
    padding-right: 16px;

    &:last-child {
      padding-right: 0;
    }
  }

  td {
    border-top: 2px solid $grey2;
  }
}
