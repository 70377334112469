.accordion {
  @include margin-spacer(10, top);
  @include margin-spacer(10, bottom);

  &__index-tag {
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    @include padding-spacer(9, left);
    @include padding-spacer(9, right);
    color: $cloud-white;
    background-color: $green-success;
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 16px;

    &--desktop {
      display: none;
    }
  }

  &__title {
    color: inherit;
    margin-top: 4px;
    margin-bottom: 4px;
    text-decoration: $link__text-decoration;
    text-decoration-thickness: $link__text-decoration-thickness;
    text-underline-offset: $link__text-underline-offset;
  }

  &__trigger {
    position: relative;
    @include button-reset();
    @include padding-spacer(6, top);
    @include padding-spacer(6, bottom);
    @include padding-spacer(9, left);
    padding-right: 56px;
    color: $link__colour;
    background-color: $white;
    cursor: pointer;

    &:hover {
      color: $link__hover-colour;
      background-color: $white;

      .accordion__title {
        text-decoration-thickness: $link__hover-text-decoration-thickness;
      }
    }
  }

  &__video {
    margin-top: 0;
  }

  &__person {
    .accordion__trigger {
      padding-right: 16px;
    }

    .accordion__icon {
      position: static;
    }

    .accordion__photo {
      position: relative;
      width: 65px;
      margin-bottom: 12px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url("/dist/images/graphics/shape-1-border_white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 100%;
        height: 100%;
      }
    }

    .accordion__trigger-content {
      display: flex;
      align-items: center;
    }

    .accordion__person-details {
      flex: 1;
      margin-right: 16px;
    }

    .accordion__icons {
      flex: 0 0 32px;
      height: 32px;
    }
  }

  &__funding-theme {
    background-color: $white;

    .accordion__trigger {
      padding-right: 16px;
    }

    .accordion__icon {
      position: static;
    }

    .accordion__photo {
      display: block;
      width: 64px;
      height: auto;
      margin-bottom: 16px;
    }

    .accordion__trigger-content {
      display: flex;
      align-items: center;
    }

    .accordion__person-details {
      flex: 1;
      margin-right: 16px;
    }

    .accordion__icons {
      flex: 0 0 32px;
      height: 32px;
    }
  }

  &__step-complete {
    .accordion__index-tag {
      background-color: $foundation-green;
    }
  }

  &.expanded {
    .accordion__icon {
      transform: rotate(180deg);
    }
  }

  &__body {
    display: none;
    @include padding-spacer(9, bottom);
    @include padding-spacer(9, left);
    @include padding-spacer(9, right);
    background-color: $white;

    .container,
    .container-inner {
      padding: 0;
      margin: 0;
      max-width: none;
    }

    .matrix--text:last-child {
      p:last-child {
        @include margin-spacer(1, bottom);
      }
    }
  }
}

.matrix--accordion--basic + .matrix--accordion--basic,
.matrix--accordion--index + .matrix--accordion--index {
  .accordion {
    margin-top: -12px;
  }
}

.matrix-section:nth-child(even) {
  .accordion {
    &__trigger,
    &__body {
      background-color: $grey3;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--accordion--basic + .matrix--accordion--basic,
  .matrix--accordion--index + .matrix--accordion--index {
    .accordion {
      margin-top: -20px;
    }
  }

  .accordion {
    &__icon {
      right: 24px;
      top: 20px;

      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }

    &__trigger {
      padding-right: 90px;
    }

    &__person {
      .accordion__trigger {
        display: flex;
        align-items: center;
        padding-right: 24px;
      }

      .accordion__trigger-content {
        flex: 1;
      }

      .accordion__photo {
        flex: 0 0 112px;
        width: 112px;
        margin-bottom: 0;
        margin-right: 24px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .accordion__trigger-content {
        display: flex;
      }

      .accordion__person-details {
        flex: 1;
        margin-right: 24px;
      }

      .accordion__icons {
        flex: 0 0 40px;
        height: 40px;
      }
    }

    &__funding-theme {
      .accordion__trigger {
        display: flex;
        align-items: center;
        padding-right: 24px;
      }

      .accordion__trigger-content {
        flex-grow: 1;
      }

      .accordion__photo {
        flex-grow: 0;
        flex-shrink: 0;
        width: 88px;
        margin-bottom: 0;
        margin-right: 24px;
      }

      .accordion__person-details {
        flex: 1;
        margin-right: 24px;
      }

      .accordion__icons {
        flex: 0 0 40px;
        height: 40px;
      }
    }
  }
}