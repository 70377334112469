.matrix--block-quote-home {
    @include margin-spacer(14, top);
    @include margin-spacer(14, bottom);

    .block-quote {
        display: flex;
        flex-direction: column-reverse;

        &__image {
            display: block;
            position: relative;
            width: 100%;
            height: calc(100vw - 32px);
            max-width: 32rem;
            max-height: 32rem;
            margin-bottom: 2.8rem;
            flex-shrink: 0;
            flex-grow: 0;
            background-color: $millennium-blue;
    
            &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url("/dist/images/graphics/shape-6-border.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            width: 100%;
            height: 100%;
            }
        }

        &__photo {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__blockquote {
            flex-grow: 1;
    
            .quotation {
                color: $foundation-green;
            }
        }

        &__source {
            color: $grey1;
        }

        @media screen and (min-width: map-get($breakpoints, sm)) {
            flex-direction: column-reverse;
            align-items: flex-start;

            &__image {
                margin-bottom: 3.2rem;
            }
        }
    
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: center;

            &__image {
                margin-left: 6.4rem;
                margin-bottom: 0;
            }
        }
    
        @media screen and (min-width: map-get($breakpoints, xl)) {
            &__image {
                max-width: 44.8rem;
                max-height: 44.8rem;
                margin-left: 10.4rem;
            }
        }
    }
}