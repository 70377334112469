.inset {
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);
  @include padding-spacer(4, top);
  @include padding-spacer(4, bottom);
  padding-left: 2.4rem;
  position: relative;

  &:before {
    content: "";
    width: .8rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 2px;
    background-color: $tower-blue;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .inset {
    padding-left: 3rem;
  }
}