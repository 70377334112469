.site-header {
  z-index: 1;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li:before {
    display: none;
  }
}

.site-header__top {
  background-color: $white;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.main-logo {
  display: block;

  svg {
    width: 7.3rem;
    height: auto;
    padding: 1rem;

    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }

  &:focus-visible {
    background-color: transparent !important;
    box-shadow: none;

    path {
      fill: $accessibility__text;
    }
  }
}

.site-header__ctas--mobile {
  display: flex;
}

.header-cta {
  display: block;
  margin-left: .8rem;
  padding: 0;
  border: 0;
  border-radius: 100%;
  background: transparent;
  color: $grey3;

  svg {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
  }

  path {
    fill: $tower-blue;
  }

  &:hover {
    color: $tower-blue;
    path {
      fill: $white;
    }
  }

  &:focus-visible {
    svg {
      rect {
        fill: $accessibility__highlight;
      }
      path {
        fill: $accessibility__text;
      }
    }
  }
}

svg.mobile-nav-button--close {
  display: none;
  transform: rotate(180deg);
}

#mobileNavButton {
  &[aria-expanded="true"] {
    svg.mobile-nav-button--burger {
      display: none;
    }

    svg.mobile-nav-button--close {
      display: block;
    }

    color: $tower-blue !important;

    path {
      fill: $white !important;
    }
  }
}

.site-header__ctas--desktop {
  display: none;
}

.site-header .search-form {
  max-width: 288px;
}

.secondary-nav--desktop {
  margin-left: 3.2rem;
  margin-bottom: 0;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    padding-left: 0;
    margin-bottom: .2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.main-nav {
  display: none;
  background-color: $tower-blue;
  color: $white;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 10;

  a {
    color: inherit;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
  }
}

.main-nav__top-level-item {
  border-top: 3px dotted $foundation-green;
  padding: 0;
  margin: 0 $container__gutter--mobile;

  &:first-child {
    margin-top: map-get($spacers--mobile, 10);
  }
}

.main-nav__top-level-a {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: map-get($spacers--mobile, 5) 0;
  cursor: pointer;

  span {
    flex-grow: 1;
  }

  svg {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    // transform: rotate(90deg);
    margin-left: .8rem;
    transition: all $transition;
  }

  &.main-nav__top-level-a--active svg {
    transform: rotate(180deg);
  }
}

.main-nav__top-level-item + .main-nav__secondary-top-level-item {
  padding-top: map-get($spacers--mobile, 10);
}

.submenu {
  display: none;
  flex-direction: column;

  li {
    padding: 0;
    margin: 0 0 1.6rem 1.6rem;
  }

  a {
    display: inline-block;
  }
}

.submenu__lead {
  margin-bottom: 2rem;

  a {
    @include margin-spacer(4, bottom);
  }
}

.submenu__items {
  border-left: 3px dotted $foundation-green;
  padding-top: map-get($spacers--mobile, 2);
  margin-bottom: map-get($spacers--mobile, 10);
}

ul.main-nav__secondary-items {
  background-color: $foundation-green;
  padding-top: map-get($spacers--mobile, 10);
  padding-bottom: map-get($spacers--mobile, 10);

  li {
    margin: 0 0 1.4rem;
    padding: 0 $container__gutter--mobile;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nav-shade {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;

  &.desktop {
    height: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .main-nav__top-level-item {
    margin: 0 $container__gutter--desktop;
  }

  ul.main-nav__secondary-items {
    li {
      padding: 0 $container__gutter--desktop;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .main-logo {
    svg {
      width: 130px;
    }
  }

  .main-nav__top-level-item {
    margin: 0 $container__gutter--lg;
  }

  ul.main-nav__secondary-items {
    li {
      padding: 0 $container__gutter--lg;
    }
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .main-logo svg {
    width: 13rem;
  }

  .site-header__ctas--mobile {
    display: none;
  }

  .nav-shade {
    &.mobile {
      height: 0;
    }
    &.desktop {
      height: 100%;
    }
  }

  .site-header__ctas--desktop {
    display: flex;
    align-items: center;
  }

  ul.main-nav__secondary-items {
    display: none;
  }

  .main-nav {
    display: block;
    background-color: $grey3;
    position: relative;

    > ul {
      flex-direction: row;
      width: 100%;
      max-width: $container__max-width;
      margin: 0 auto;
      padding-left: $container__gutter--lg;
      padding-right: $container__gutter--lg;
    }
  }

  .main-nav__top-level-item {
    border: 0;
    margin: 0 3.2rem 0 0;
    position: initial;

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(3),
    &:nth-child(4) {
      .submenu {
        background-color: $foundation-green;
        color: $cloud-white;
      }
    }
  }

  a.main-nav__top-level-a {
    color: $tower-blue;
    margin: 2.4rem 0;

    svg {
      color: $river-blue;
    }

    &:hover {
      color: $river-blue;
    }
  }

  .submenu {
    background-color: $southwark-mint;
    position: absolute;
    left: 0;
    right: 0;
    padding: 4.8rem 0 5.6rem;
    color: $foundation-green;

    li {
      margin: 0 0 1.6rem 3.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .submenu__container {
    display: flex;
    width: 100%;
    max-width: $container__max-width;
    margin: 0 auto;
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }

  .submenu__lead {
    margin: 0;
    width: 352px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 3.2rem;
  }

  .submenu__items {
    margin: 0;
    padding-top: 0;
    flex-grow: 1;
  }
}

//------//
// > xl //
//------//

@media screen and (min-width: map-get($breakpoints, xl)) {
  .site-header__top .container {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  .main-logo {
    svg {
      width: 22.2rem;
      padding: 0;

      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
      }
    }
  }
}