.matrix--home-cta-video {
  @include margin-spacer(14, top);
  @include margin-spacer(14, bottom);
}

.home-cta-video {
  display: flex;
  flex-direction: column-reverse;
}

.home-cta-video__video {
  margin: 0;
  @include margin-spacer(10, bottom);
}

.home-cta-video__content {
  h2 {
    @include margin-spacer(4, bottom);
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .home-cta-video {
    flex-direction: row;
    align-items: center;
  }

  .home-cta-video__content,
  .home-cta-video__video {
    width: 50%;
  }

  .home-cta-video__content {
    padding-right: 4rem;
  }

  .home-cta-video__video {
    padding-left: 4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .home-cta-video__content {
    padding-right: 8rem;
  }

  .home-cta-video__video {
    padding-left: 0;
  }
}