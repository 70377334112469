.related-content {
  &__heading {
    @include padding-spacer(14, top);
    @include padding-spacer(10, bottom);

    &__standard {
      background-color: $southwark-mint;

      h2 {
        color: $london-stone;
      }
    }
  }

  &__entries {
    @include padding-spacer(14, bottom);

    & > .row {
      position: relative;
    }
  }

  &__background-wrap {
    position: relative;
  }

  &__background-size {
    position: absolute;
    top: 32px;
    width: 100%;
    padding-top: 56.25%;
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$container__gutter--mobile;
    right: -$container__gutter--mobile;
    background-color: $southwark-mint;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .related-content {
    &__background {
      left: -$container__gutter--desktop;
      right: -$container__gutter--desktop;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .related-content {
    &__background {
      left: -$container__gutter--lg;
      right: -$container__gutter--lg;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .related-content {
    overflow: hidden; // hide overflown background graphics around thumbnail

    &__background-wrap {
      width: calc(50% - 16px);
    }

    &__background {
      left: -9999px;
      right: -9999px;
    }
  }
}