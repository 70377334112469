.matrix {
  &:first-child {
    .container--narrow {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }

  &:last-child {
    .container--narrow {
      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--block-quote,
  &--cta-graphic,
  &--cta-photo {
    &:last-child {
      margin-bottom: map-get($spacers--mobile, 14) * -1;
    }
  }

  &--cta-graphic:first-child {
    .cta-graphic {
      margin-top: 0;
    }
  }

  &--cta-photo:first-child {
    .cta-photo {
      padding-top: 0;
    }
  }
}

.matrix-section {
  @include padding-spacer(14, top);
  @include padding-spacer(14, bottom);

  &:nth-child(even) {
    background-color: $white;
  }
}

.hero + .matrix-sections {
  margin-top: map-get($spacers--mobile, 14) * -1;
}

// Remove top padding on matrix sections if it immediately follows entry details
// This is most apparent on the grant template, if no related entries are found

.entry-details + .matrix-sections > .matrix-section:first-child {
  padding-top: 0;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix {
    &--block-quote,
    &--cta-graphic,
    &--cta-photo {
      &:last-child {
        margin-bottom: map-get($spacers--desktop, 14) * -1;
      }
    }
  }

  .hero + .matrix-sections {
    margin-top: map-get($spacers--desktop, 14) * -1;
  }
}