.hero {
  @include margin-spacer(14, bottom);
}

.hero__top {
  background-color: $foundation-green;
  color: $cloud-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3.6rem;

  &--what-we-do {
    background-color: $tower-blue;
  }

  &--news-and-blog,
  &--news-and-events,
  &--events {
    background-color: $river-blue;
  }

  *:not(.button--invert) {
    color: inherit;
  }

  h1 {
    margin: 0;
    max-width: 692px;
    @include margin-spacer(10, top);
  }

  .standfirst {
    margin-top: 2rem;
  }
}

.hero__top__icon {
  @include margin-spacer(10, top);
  @include margin-spacer(8, bottom);
  background-color: $grey3;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    height: auto;
    width: 100%;
    max-width: 5.2rem;
  }

  + h1 {
    margin-top: 0;
  }
}

.hero--has-image {
  .hero__top {
    padding-bottom: 8.4rem;
  }
}

.hero__links {
  @include margin-spacer(6, top);
}

.hero__image-inner-container {
  position: relative;
  padding-top: $container__gutter--mobile;
  padding-bottom: $container__gutter--mobile;
  margin-top: -64px;

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .content-image__caption {
    position: relative;
    margin-bottom: 0;
  }
}

.hero--homepage {
  margin-bottom: 0;
  overflow: hidden;

  &__image {
    position: relative;
  
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__contents {
    background-color: $foundation-green;
    color: $cloud-white;
    @include padding-spacer(16, bottom);

    .container--narrow {
      max-width: 900px;
    }
  
    h1, .standfirst {
      color: inherit;
      position: relative;
    }
  
    h1 {
      margin-top: -4rem;
      margin-bottom: calc(map-get($spacers--mobile, 10) + .4rem);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .hero__top {
    padding-bottom: 5.6rem;
  }

  .hero--has-image .hero__top {
    padding-bottom: 18.4rem;
  }

  .hero__image-inner-container {
    padding-top: $container__gutter--desktop;
    padding-bottom: $container__gutter--desktop;
    margin-top: -160px;
  }

  .hero--homepage {
    &__contents h1 {
      margin-top: -7.1rem;
      margin-bottom: calc(40px + .4rem);
    }

    &__image {
      height: 360px;
      @media screen and (min-width: map-get($breakpoints, xxl)) {
        height: 587px;
      }
  
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .hero__top {
    background-image: url("/dist/images/graphics/shape-2-green.svg");
    background-repeat: no-repeat;
    background-position: top 50% right -157px;
    background-size: 600px;

    &--what-we-do {
      background-image: url("/dist/images/graphics/shape-1-blue.svg");
    }

    &--news-and-blog,
    &--news-and-events,
    &--events {
      background-image: url("/dist/images/graphics/shape-9-blue.svg");
    }
  }
}