.pagination {
  text-align: center;

  &__heading {
    font-family: $base__font-family;
    color: $base__font-colour;
    font-weight: $font-weight--normal;
    @include margin-spacer(14, top);
    @include margin-spacer(6, bottom);
  }

  &__numbers {
    @include list-reset();
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      margin-left: 8px;
      margin-right: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50%;
    font-weight: $font-weight--bold;
    text-decoration: none;

    &:hover {
      color: $cloud-white;
      background-color: $link__colour;
    }

    &:active {
      color: $cloud-white !important;
      background-color: $primary-colour;
      box-shadow: none;
    }

    &:focus-visible {
      color: $accessibility__text !important;
      background-color: $accessibility__highlight;
      box-shadow: none;
    }

    &__current {
      color: $cloud-white;
      background-color: $river-blue;

      &:active {
        background-color: $river-blue;
      }
    }

    &.disabled {
      color: $grey2;
    }

    &__prev,
    &__next {
      background-color: transparent;

      &:focus-visible {
        box-shadow: $accessibility__inner-box-shadow;
      }

      &.disabled {
        &:hover {
          background-color: transparent;
        }

        &:active {
          color: $grey2 !important;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }

  &--desktop {
    display: none;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .pagination {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }
  }
}