@mixin button($btn__text-colour, $btn__background-colour, $btn__hover-text-colour, $btn__hover-background-colour) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn__background-colour;
  color: $btn__text-colour;
  border: 0;
  outline: 0;
  border-radius: $btn__border-radius;
  padding: $btn__padding;
  font-weight: $btn__font-weight;
  text-decoration: none;
  line-height: 24px;
  transition: all $transition;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  &:hover {
    color: $btn__hover-text-colour;
    background-color: $btn__hover-background-colour;
  }

  &__icon {
    height: auto;
    display: block;
    flex-shrink: 0;

    &--chevron {
      width: 9px;
      margin-left: 12px;
      margin-right: -8px;
      margin-top: -1px;
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding: $btn__padding-desktop;
    line-height: 30px;

    &__icon {
      &--chevron {
        width: 12px;
        margin-left: 16px;
      }
    }
  }
}

.button,
button {
  @include button($btn__text-colour, $btn__background-colour, $btn__text-colour, $btn__hover-background-colour);
}

.button {
  &--invert {
    @include button($river-blue, $cloud-white, $cloud-white, $btn__hover-background-colour);
  }
}