.list-entries {
  @include margin-spacer(14, top);
  @include margin-spacer(14, bottom);

  &__hero {
    margin-bottom: 32px;
  }
}

.home-latest-updates {
  background-color: $grey3;
  @include padding-spacer(14, top);
  @include padding-spacer(14, bottom);

  .list-entries {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .list-entries {
    &__hero {
      @include margin-spacer(14, bottom);
    }

    &__list {
      & > .row {
        margin-top: map-get($spacers--desktop, 14) * -1;

        & > * {
          @include margin-spacer(14, top);
        }
      }

      &__one-column {
        & > .row {
          margin-top: map-get($spacers--desktop, 10) * -1;
  
          & > * {
            @include margin-spacer(10, top);
          }
        }
      }
    }
  }
}