.social-share {
  position: relative;
  z-index: 10;

  &__trigger {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  &__heading {
    color: inherit;
    font-weight: $font-weight--normal;
    margin: 0;
  }

  &__links__triangle {
    display: block;
    width: 32px;
    height: auto;
    @include margin-spacer(7, left);
  }

  &__links {
    width: 100%;
    max-width: 238px;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;

    &.show {
      display: block;
    }
  }

  &__btns {
    color: $white;
    background-color: $link__colour;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include padding-spacer(8, top);
    @include padding-spacer(7, right);
    @include padding-spacer(5, bottom);
    @include padding-spacer(7, left);
  }

  &__btn {
    color: $white;
    display: inline-flex;
    align-items: center;
    @include margin-spacer(5, bottom);

    svg {
      flex: 0 0 32px;
      height: auto;
      @include margin-spacer(4, right);
    }

    &:hover {
      color: $white;
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .social-share {
    &__links__triangle {
      width: 40px;
    }

    &__btn {
      svg {
        flex: 0 0 40px;
      }
    }
  }
}