.signpost-links-grid {
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);

  & > .row {
    margin-top: map-get($spacers--mobile, 8) * -1;

    & > .col {
      @include margin-spacer(8, top);
    }
  }

  &__link {
    flex: 0 1 calc(50% - 14px);
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .signpost-links-grid {
    &__link {
      flex: 0 1 calc(50% - 14px);
    }
  }
}