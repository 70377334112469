.entry-details {
  &__list {
    @include list-reset();
    @include margin-spacer(5, bottom);

    li {
      @include padding-spacer(4, top, 1.5);
      @include padding-spacer(4, bottom, 1.5);
      margin-bottom: 0;
      @include dotted-border("top");

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__excerpt {
    @include list-reset();

    li {
      @include margin-spacer(2, bottom);
    }
  }

  &__tags {
    @include margin-spacer(8, top);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include margin-spacer(8, bottom);
  }

  &__button {
    @include margin-spacer(5, bottom);
  }

  &.caption {
    margin-bottom: 0;
  }
}

.hero {
  .entry-details {
    @include padding-spacer(14, top);
    @include padding-spacer(14, bottom);
    background-color: $cloud-white;

    &--transparent-bg {
      @include padding-spacer(10, top);
      padding-bottom: 0;
      background-color: transparent;
    }
  }
}

#content > .entry-details {
  @include margin-spacer(8, bottom);
}