.full-width-call-to-action {
  background-color: $foundation-green;
  color: $cloud-white;
  display: flex;
  flex-direction: column;

  h2, .body-lg {
    color: inherit;
  }

  h2 {
    @include margin-spacer(7, bottom);
  }
}

.full-width-call-to-action__image {
  position: relative;
  
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url("/dist/images/graphics/shape-2-border.svg");
    background-repeat: no-repeat;
    background-position: center top 40%;
    background-size: 100%;
    width: 101%; // Get rid of the line that appears on some screens.
    height: 100%;
  }
}

.full-width-call-to-action__content {
  @include padding-spacer(12, top);
  @include padding-spacer(14, bottom);

  .container--narrow {
    padding-left: $container__gutter--mobile;
    padding-right: $container__gutter--mobile;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .full-width-call-to-action__content {
    .container--narrow {
      padding-left: $container__gutter--desktop;
      padding-right: $container__gutter--desktop;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .full-width-call-to-action__content {
    .container--narrow {
      padding-left: $container__gutter--lg;
      padding-right: $container__gutter--lg;
    }
  }

  .full-width-call-to-action__image {
    height: 360px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .full-width-call-to-action {
    position: relative;
    flex-direction: row;
  }

  .full-width-call-to-action__image,
  .full-width-call-to-action__content {
    width: 50%;
  }

  .full-width-call-to-action__image {
    position: absolute;
    height: 100%;
  }

  .full-width-call-to-action__content {
    margin-left: 50%;
    display: flex;
    align-items: center;
    @include padding-spacer(16, top);
    @include padding-spacer(16, bottom);

    .container--narrow {
      max-width: calc($container__max-width / 2);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .full-width-call-to-action__content .container--narrow {
    padding-left: 80px;
  }
}