//---------//
// Colours //
//---------//

// Brand colours
$white: #FFF;
$london-stone: #222222;
$cloud-white: #FBF8F4;
$tower-blue: #426FAE;
$river-blue: #090369;
$millennium-blue: #6EB3C4;
$foundation-green: #04512C;
$blackfriars-red: #AA2B3C;
$southwark-mint: #8BD196;
$grey1: #545454;
$grey2: #8EA6B3;
$grey3: #F3F3F3;

// Colour assignment
$primary-colour: $foundation-green;
$red-error: $blackfriars-red;
$green-success: $foundation-green;

// Accessibility focus state colours
$accessibility__highlight: #FFDD00;
$accessibility__text: $foundation-green;
$accessibility__box-shadow: 0 3px $accessibility--text;
$accessibility__inner-box-shadow: inset 0 0 0 3px $accessibility--text;

//--------//
// Layout //
//--------//

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px
);

// Grid
$grid-columns: 12;
$grid-gutter-width: 1.5rem;

// Container
$container__max-width: 1216px;
$container--lg__max-width: 1024px;
$container--narrow__max-width: 736px;
$container__gutter--mobile: 16px;
$container__gutter--desktop: 32px;
$container__gutter--lg: 48px;

// Vertical spacers
$spacers--mobile: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 10px,
  5: 14px,
  6: 16px,
  7: 18px,
  8: 20px,
  9: 24px,
  10: 28px,
  11: 32px,
  12: 36px,
  13: 40px,
  14: 44px,
  15: 48px,
  16: 52px,
  17: 56px,
  18: 60px
);

$spacers--desktop: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 12px,
  5: 16px,
  6: 20px,
  7: 24px,
  8: 28px,
  9: 32px,
  10: 40px,
  11: 48px,
  12: 56px,
  13: 64px,
  14: 72px,
  15: 80px,
  16: 96px,
  17: 112px,
  18: 128px
);

//------------//
// Typography //
//------------//

// Weights
$font-weight--normal: 400;
$font-weight--medium: 500;
$font-weight--semi-bold: 600;
$font-weight--bold: 700;

// Base
$base__font-family: 'Lato', sans-serif;
$base__font-colour: $london-stone;
$base__font-size--mobile: 1.8rem;
$base__line-height--mobile: 2.4rem;
$base__font-size--desktop: 2.2rem;
$base__line-height--desktop: 3rem;
$base__font-weight: $font-weight--normal;

// Headings
$heading__font-family: 'Atlantic', sans-serif;
$heading__font-colour: $primary-colour;
$heading__letter-spacing--mobile: -.036rem;
$heading__letter-spacing--desktop: -.064rem;

// H1 - page heading
$h1__colour: $heading__font-colour;
$h1__font-weight: $font-weight--medium;
$h1__font-size--mobile: 3.6rem;
$h1__line-height--mobile: 3.4rem;
$h1__font-size--desktop: 6.4rem;
$h1__line-height--desktop: 6.4rem;
$h1__letter-spacing--mobile: $heading__letter-spacing--mobile;
$h1__letter-spacing--desktop: $heading__letter-spacing--desktop;

// H2 - secion heading
$h2__colour: $heading__font-colour;
$h2__font-weight: $font-weight--semi-bold;
$h2__font-size--mobile: 2.3rem;
$h2__line-height--mobile: 3rem;
$h2__font-size--desktop: 3.8rem;
$h2__line-height--desktop: 4.6rem;

// H3 - cross heading
$h3__colour: $heading__font-colour;
$h3__font-weight: $font-weight--semi-bold;
$h3__font-size--mobile: 2rem;
$h3__line-height--mobile: 2.6rem;
$h3__font-size--desktop: 2.8rem;
$h3__line-height--desktop: 3.6rem;

// H Paragraph - paragraph heading
$h-para__colour: $base__font-colour;
$h-para__font-weight: $font-weight--bold;
$h-para__font-size--mobile: 1.8rem;
$h-para__line-height--mobile: 2.4rem;
$h-para__font-size--desktop: 2.3rem;
$h-para__line-height--desktop: 3rem;

// standfirst
$stf__colour: $heading__font-colour;
$stf__font-weight: $font-weight--normal;
$stf__font-size--mobile: 2rem;
$stf__line-height--mobile: 2.6rem;
$stf__font-size--desktop: 2.6rem;
$stf__line-height--desktop: 3.4rem;

// quotation
$quot__colour: $cloud-white;
$quot__font-weight: $font-weight--medium;
$quot__font-size--mobile: 2rem;
$quot__line-height--mobile: 2.6rem;
$quot__font-size--desktop: 2.6rem;
$quot__line-height--desktop: 3.4rem;

// Body - SM
$body-sm__font-size--mobile: 1.6rem;
$body-sm__line-height--mobile: 2.2rem;
$body-sm__font-size--desktop: 2rem;
$body-sm__line-height--desktop: 2.6rem;

// Links
$link__colour: $tower-blue;
$link__hover-colour: $river-blue;
$link__text-decoration: underline;
$link__text-decoration-thickness: 1px;
$link__hover-text-decoration-thickness: 2px;
$link__text-underline-offset: 3px;

//---------//
// Buttons //
//---------//

$btn__background-colour: $river-blue;
$btn__hover-background-colour:$link__colour;
$btn__text-colour: $cloud-white;
$btn__border-radius: 200px;
$btn__padding: 10px 24px 8px;
$btn__padding-desktop: 10px 32px 8px;
$btn__font-weight: $font-weight--normal;

//-------------------------//
// Animation & interaction //
//-------------------------//

$transition__duration: 0.2s;
$transition__curve: ease;
$transition: $transition__duration $transition__curve;

$hover__effect: darken; // darken or lighten
$hover__effect-amount: 10%;