.cookie-banner {
  background-color: $foundation-green;
  color: $cloud-white;
  width: 100%;
  @include padding-spacer(10, top);
  @include padding-spacer(11, bottom);
  overflow: auto;
  z-index: 1;

  h2, h3, a:not(.button) {
    color: inherit;
  }

  h2 {
    margin-top: 0;
    @include margin-spacer(6, bottom);
  }

  p {
    @include margin-spacer(4, bottom);
  }
}

.cookie-banner__actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include padding-spacer(6, top);
  margin: -4px;

  .button {
    margin: 4px;
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .cookie-banner__actions {
    flex-direction: row;
    align-items: center;
    margin: -6px;
  }
}
