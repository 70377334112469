.search-form {
  position: relative;

  input[type="search"] {
    width: 100%;
    padding: 0 6rem 0 1.2rem;
    height: 4.8rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: $tower-blue;
    color: $cloud-white;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4.8rem;
    height: 4.8rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
    cursor: pointer;

    svg {
      display: block;
    }

    &:hover {
      background-color: $river-blue;
    }

    &:active {
      background-color: $london-stone;
    }

    &:focus-visible {
      box-shadow: $accessibility__inner-box-shadow;
    }
  }
}

#content .search-form {
  width: 100%;
  max-width: 448px;
}

.search-results,
.search-results__pagination {
  @include margin-spacer(14, bottom);
}

.search-results__count {
  display: block;
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);
}

.search-results__result {
  margin: 0;
  padding: 0;
  @include padding-spacer(4, top, 1.5);
  @include margin-spacer(8, bottom);
  @include dotted-border("top");

  &:before {
    display: none;
  }
}