.video {
  position: relative;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
  padding-top: $container__gutter--mobile;

  &__frame {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
    overflow: hidden;

    &--transparent {
      background-color: transparent;
    }
  }

  &__asset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    @include button-reset;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }

  &__image {
    display: block;
  }

  &__play-button {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: $white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
  }

  &__play-icon {
    color: $link__colour;
    width: 16px;
  }

  &:hover {
    .video__play-button {
      background-color: $grey3;
    }

    .video__play-icon {
      color: $link__hover-colour;
    }
  }

  &:active {
    .video__play-button {
      background-color: $accessibility__highlight;
      box-shadow: $accessibility__inner-box-shadow;
    }

    .video__play-icon {
      color: $accessibility__text;
    }
  }

  &__playing {
    .video__image,
    .video__button {
      display: none;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .video {
    padding-top: $container__gutter--desktop;

    &__play-button {
      width: 72px;
      height: 72px;
      margin-top: -36px;
      margin-left: -36px;
      padding-left: 8px;
    }

    &__play-icon {
      width: 24px;
    }
  }
}