.icon-link {
  display: table; // for .icon-link__link width in focus 
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);
}

.icon-link__link {
  display: flex;
  align-items: flex-start;
  font-weight: $font-weight--bold;
  text-decoration: none;

  .icon-link__text > span:first-child {
    text-decoration: $link__text-decoration;
    text-decoration-thickness: $link__text-decoration-thickness;
    text-underline-offset: $link__text-underline-offset;
  }

  &:hover .icon-link__text > span:first-child {
    text-decoration-thickness: $link__hover-text-decoration-thickness;
  }

  &:focus-visible path {
    fill: $cloud-white !important;
  }

  &:focus-visible .icon-link__text > span:first-child {
    text-decoration: none;
  }
}

.icon-link__icon {
  display: block;
  width: 48px;
  height: 48px;
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-link__icon--desktop {
  display: none;
}

.icon-link__text {
  margin: 12px 0 0 12px;

  span {
    display: block;
  }
}

.icon-link__description,
.icon-link__download-meta {
  display: block;
  color: $base__font-colour;
  font-weight: $base__font-weight;
  margin: 0;
  @include margin-spacer(2, top);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .icon-link__text {
    margin: 12px 0 0 16px;
  }

  .icon-link__icon {
    width: 52px;
    height: 52px;
  }

  .icon-link__icon--desktop {
    display: block;
  }

  .icon-link__icon--mobile {
    display: none;
  }
}