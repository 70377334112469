.statistics {
  display: flex;
  flex-direction: column;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
}

.statistic {
  @include margin-spacer(8, bottom);
  @include padding-spacer(10, top);
  @include padding-spacer(10, bottom);
  @include padding-spacer(9, left);
  @include padding-spacer(9, right);

  .h1.statistic__figure {
    color: inherit;
  }

  &:first-child {
    background-color: $millennium-blue;
    color: $river-blue;
  }

  &:nth-child(2) {
    background-color: $southwark-mint;
    color: $foundation-green;
  }

  &:nth-child(3) {
    background-color: $tower-blue;
    color: $cloud-white;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.statistic__figure {
  display: block;
  word-wrap: anywhere;
  margin-bottom: 0;
}

.statistic__caption {
  margin-top: 0;
}

@media (min-width: map-get($breakpoints, lg)) {
  .statistics {
    flex-direction: row;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }

  .statistic {
    flex: 1;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 0;
  }
}