.content-image {
  position: relative;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
  padding-top: $container__gutter--mobile;

  &__asset {
    position: relative;
    display: block;
  }

  &__caption {
    @include padding-spacer(8, top);
    @include padding-spacer(8, bottom);
    @include padding-spacer(9, left);
    @include padding-spacer(9, right);
    background-color: $white;
  }

  &__image-shape {
    position: relative;

    &.graphic-enabled::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: -1px;
      background-image: url("/dist/images/graphics/image-side-shape.svg");
      background-repeat: no-repeat;
      background-position: left;
      background-size: auto 100%;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .content-image {
    padding-top: $container__gutter--desktop;
  }
}