.info-box {
  background-color: $foundation-green;
  color: $cloud-white;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
  @include padding-spacer(10, top);
  @include padding-spacer(10, bottom);
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  h1, h2, h3, a {
    color: inherit;
  }

  ul li:before {
    background-color: $cloud-white;
  }

  .button {
    @include margin-spacer(8, top);
    @include margin-spacer(8, bottom);
  }
}

.matrix.matrix--info-box-v2,
.matrix.matrix--info-box {
  &:last-child .container--narrow > .button:last-child {
    @include margin-spacer(8, bottom);
  }
}

.info-box__blocks {
  .container,
  .container-inner {
    padding: 0;
    margin: 0;
    max-width: none;
  }

  .matrix--text:last-child {
    p:last-child {
      @include margin-spacer(1, bottom);
    }
  }

  .icon-link__link {
    .icon-link__icon {
      color: $cloud-white;

      path {
        fill: $foundation-green;
      }
    }

    &:focus-visible {
      .icon-link__icon {
        color: $accessibility__text;

        path {
          fill: $cloud-white !important;
        }
      }
    }
  }


}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .info-box {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}