.entry-card {
  position: relative;
  height: 100%;
  background-color: $cloud-white;
  cursor: pointer;

  &__thumbnail {
    position: relative;

    img {
      position: relative;
      display: block;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      background-image: url("/dist/images/graphics/shape-1-border-bottom.svg"); // This file doesn't work with 'clip-path' as it uses pixels in its path so isn't responsive.
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100%;
      width: 100%;
      height: 100%;
    }

    &__graphic-background {
      position: relative;
      padding-top: 56.25%;

      img {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__related-grants--thumbnail-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    img.entry-card__related-grants--thumbnail {
      position: relative;
      object-fit: contain;
      height: 49.25%;
      width: auto;
      margin-bottom: 3.75%;
    }
  }

  &__content {
    @include padding-spacer(9, top);
    @include padding-spacer(9, left);
    @include padding-spacer(9, right);
    @include padding-spacer(11, bottom);
  }

  &__heading {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 0;
    @include margin-spacer(5, bottom);

    @media screen and (min-width: map-get($breakpoints, sm)) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__excerpt {
    @include margin-spacer(5, bottom);
  }

  &:active {
    .entry-card__heading > a {
      color: $accessibility__text !important;
      text-decoration: none !important;
      background-color: $accessibility__highlight;
      box-shadow: $accessibility__box-shadow;
    }
  }

  &__links {
    @include margin-spacer(5, top);
  }
}

/* Hero cards on the listing pages */

.entry-card__hero {
  color: $cloud-white;
  background-color: $river-blue;

  .entry-card {
    &__thumbnail {
      &::after {
        background-image: none;
      }
  
      &-wrap {
        position: relative;
  
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &__heading {
      font-size: $h3__font-size--mobile;
      line-height: $h3__line-height--mobile;

      @media screen and (min-width: map-get($breakpoints, sm)) {
        font-size: $h3__font-size--desktop;
        line-height: $h3__line-height--desktop;
      }

      a {
        color: $cloud-white;
      }
    }

    &__link {
      @extend .button--invert;
      box-shadow: inset 0 0 0 1px transparent;
    }
  }
  
  &--news-and-blog {
    background-color: $river-blue;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    .entry-card {
      &__thumbnail {
        &-wrap {
          height: 360px;
    
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, md)) {
    &-graphic {
      padding: 36px;
    }
  }

  @media screen and (min-width: map-get($breakpoints, lg)) {
    display: flex;
    align-items: stretch;

    &-graphic {
      width: 100%;
      height: auto;
      padding: 72px;
    }

    .entry-card {
      &__content, &__thumbnail {
        flex: 0 0 50%;
      }

      &__content {
        padding-left: 0;
        @include padding-spacer(16, right);
        @include padding-spacer(9, bottom);
        align-self: center;
      }

      &__thumbnail {
        @include margin-spacer(9, right);

        &-wrap {
          position: relative;
          height: 100%;
    
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: -1px;
            background-image: url("/dist/images/graphics/shape-4-border-side.svg"); // This file doesn't work with 'clip-path' as it uses pixels in its path so isn't responsive.
            background-repeat: no-repeat;
            background-position: right;
            background-size: auto 100%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &--news-and-blog {
      .entry-card__thumbnail-wrap::after {
        background-image: url("/dist/images/graphics/shape-7-border-right.svg");
      }
    }
  }
}

/* Entry cards on the job listing page */

.entry-card__with-graphic {
  .entry-card__thumbnail {
    @include padding-spacer(9, top);
    @include padding-spacer(9, left);
  }

  .entry-card__thumb-graphic {
    height: 104px;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }

  @media screen and (min-width: map-get($breakpoints, lg)) {
    display: flex;
    flex-direction: row-reverse;

    .entry-card__thumbnail {
      @include padding-spacer(12, top);
      @include padding-spacer(12, left);
      @include padding-spacer(12, right);
      @include padding-spacer(12, bottom);
    }

    .entry-card__thumb-graphic {
      width: 176px;
      height: 176px;
    }

    .entry-card__content {
      @include padding-spacer(12, top);
      @include padding-spacer(12, left);
      @include padding-spacer(10, right);
      @include padding-spacer(12, bottom);
    }
  }
}

.col {
  &:nth-child(5n+1) {
    .entry-card__thumb-graphic {
      background-image: url("/dist/images/graphics/shape-5-blue.svg");
    }
  }

  &:nth-child(5n+2) {
    .entry-card__thumb-graphic {
      background-image: url("/dist/images/graphics/shape-4-red.svg");
    }
  }

  &:nth-child(5n+3) {
    .entry-card__thumb-graphic {
      background-image: url("/dist/images/graphics/shape-6-green.svg");
    }
  }

  &:nth-child(5n+4) {
    .entry-card__thumb-graphic {
      background-image: url("/dist/images/graphics/shape-2-blue.svg");
    }
  }

  &:nth-child(5n+0) {
    .entry-card__thumb-graphic {
      background-image: url("/dist/images/graphics/shape-1-green.svg");
    }
  }
}