.matrix--block-quote {
  position: relative;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
  background-color: $foundation-green;

  .block-quote {
    color: $quot__colour;

    &__container {
      @include padding-spacer(12, top);
      @include padding-spacer(12, bottom);
    }

    &__hex {
      display: block;
      margin: 0 auto;
      position: absolute;
      left: $container__gutter--mobile;
      top: -24px;
      width: 104px;
      height: calc(104px * 0.866); /* height = width * 0.866 */
      box-sizing: border-box;
      clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__blockquote {
      margin: 0;

      &--photo {
        margin-top: 42px;
      }
    }
  }

  &.river-blue {
    background-color: $river-blue;
  }

  &.southwark-mint {
    background-color: $southwark-mint;
    .block-quote, .quotation {
      color: $foundation-green;
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    .block-quote {
      &__hex {
        left: $container__gutter--desktop;
        top: -32px;
        width: 172px;
        height: calc(172px * 0.866);
      }

      &__blockquote {
        &--photo {
          margin-top: 82px;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, lg)) {
    .block-quote {
      &__hex {
        left: auto;
        right: 0;
        top: 0;
        width: 300px;
        height: 100%;
        clip-path: none;
        background-color: $southwark-mint;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: -1px;
          background-image: url("/dist/images/graphics/shape-7-border-left.svg"); // This file doesn't work with 'clip-path' as it uses pixels in its path so isn't responsive.
          background-repeat: no-repeat;
          background-position: left;
          background-size: auto 100%;
          width: 100%;
          height: 100%;
        }
      }

      &__blockquote {
        padding-right: 108px;

        &--photo {
          margin-top: 0;
        }
      }
    }

    &.river-blue .block-quote__hex {
      background-color: $tower-blue;
      &::after {
        background-image: url("/dist/images/graphics/shape-7-border-left_blue.svg");
      }
    }
  
    &.southwark-mint .block-quote__hex {
      background-color: $foundation-green;
      &::after {
        background-image: url("/dist/images/graphics/shape-7-border-left_mint.svg");
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, xl)) {
    .block-quote {
      &__hex {
        width: 500px;
      }
    }
  }
}