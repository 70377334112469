.matrix--cta-graphic {
  position: relative;
  background-color: $foundation-green;
  color: $cloud-white;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
}

.cta-graphic {
  @include padding-spacer(12, bottom);

  &__graphic {
    &--mobile {
      display: block;
      margin-left: -$container__gutter--desktop;
    }

    &--container,
    &--desktop {
      display: none;
    }
  }

  &__heading {
    color: $cloud-white;
    @include margin-spacer(12, top);
    @include margin-spacer(6, bottom);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .cta-graphic {
    &__graphic {
      &--mobile {
        margin-left: -$container__gutter--lg;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .cta-graphic {
    @include padding-spacer(12, top);

    &__graphic {
      &--mobile {
        display: none;
      }

      &--container {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 198px;
        height: 100%;
        overflow: hidden;
      }

      &--desktop {
        display: block;
        position: absolute;
        top: -50%;
        width: 679px;
        max-width: 679px; // override default image style
      }
    }

    &__heading {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .cta-graphic {
    &__graphic {
      &--container {
        width: 388px;
      }
    }
  }
}