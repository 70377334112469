.list-filter {
  &__cat-list {
    @include margin-spacer(7, bottom);
  }

  &__cat-list-title {
    display: block;
    color: $london-stone;
    @include margin-spacer(5, bottom);
  }

  &__cat-tags {
    li {
      input[type=radio] + .list-filter__cat-tag, 
      input[type=checkbox] + .list-filter__cat-tag {
        color: $link__colour;
        // border: 2px solid $link__colour;
        cursor: pointer;

        &:hover {
          color: $cloud-white;
          background-color: $link__colour;
          border-color: $link__hover-colour;
        }

        &:active {
          color: $accessibility__text !important;
          background-color: $accessibility__highlight;
          border-color: $accessibility__text;
          box-shadow: $accessibility__inner-box-shadow;
        }
      }

      input[type=radio]:checked + .list-filter__cat-tag,
      input[type=checkbox]:checked + .list-filter__cat-tag {
        color: $cloud-white;
        background-color: $link__colour;
        border-color: $link__hover-colour;
    
        &:active {
          color: $accessibility__text !important;
          background-color: $accessibility__highlight;
          border-color: $accessibility__text;
          box-shadow: $accessibility__inner-box-shadow;
        }
      }
    }
  }

  &__cat-select {
    box-shadow: none;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.00006 0L0 2L6.99992 8.99992L6.99994 9L6.99997 8.99997L7 9L7.00003 8.99992L13.9999 2L12.0001 0L7.00006 5.00005L2.00006 0Z' fill='%23426FAE'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 12px) center;
    background-repeat: no-repeat;
  }

  &__buttons {
    @include margin-spacer(11, top);
  }

  &__applied-content {
    @include margin-spacer(3, top);
  }

  &__result-number {
    @include margin-spacer(8, top);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .list-filter {
    &__cat-select {
      max-width: 320px;
      padding-left: 12px;
      padding-right: 12px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.00008 0L0 2L7.99839 10L7.99978 9.99768L8.00118 10L15.9996 2L13.9997 0L7.99982 5.99993L2.00008 0Z' fill='%23426FAE'/%3E%3C/svg%3E%0A");
      background-position: calc(100% - 16px) center;
    }

    &__buttons {
      display: flex;
      flex-direction: row-reverse;
    }

    &__submit-button:not(:last-child) {
      margin-right: 0;
    }
  
    &__applied-content {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      margin-top: 0;
    }
  
    &__result-number {
      margin-top: 0;
    }
  }
}