.breadcrumb {
  @include padding-spacer(9, top);
  @include padding-spacer(9, bottom);
  margin: 0;
  font-size: $body-sm__font-size--mobile;
  line-height: $body-sm__line-height--mobile;

  ol {
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li{
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;

      &:before {
        display: none;
      }

      svg {
        display: block;
        margin: 0 1rem;
        width: auto;
        height: 1rem;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .breadcrumb {
    font-size: $body-sm__font-size--desktop;
    line-height: $body-sm__line-height--desktop;
  }
}