.cookie-preference-toggle {
  fieldset {
    margin: 0;
    @include margin-spacer(10, bottom);
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}