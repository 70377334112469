.cta-photo {
  @include padding-spacer(10, top);
  @include padding-spacer(10, bottom);

  &__photo {
    @include margin-spacer(9, bottom);
    position: relative;
    width: 162px;
    
    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("/dist/images/graphics/shape-7-border_white.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &__heading {
    margin-top: 0;
    @include margin-spacer(6, bottom);
  }
}

.frame2.cta-photo__photo::after {
  background-image: url("/dist/images/graphics/shape-6-border_white.svg");
}

.matrix--cta-photo {
  background-color: $white;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .cta-photo {
    &__photo {
      width: 240px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .cta-photo {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &__photo {
      flex: 0 0 400px;
      margin-bottom: 0;
      width: 400px;
    }

    &__content {
      margin-right: 64px;
    }
  }
}