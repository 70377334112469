main {
  background-color: $grey3;

  .homepage & {
    background-color: $cloud-white;
  }
}

.container {
  width: 100%;
  max-width: $container__max-width;
  padding-left: $container__gutter--mobile;
  padding-right: $container__gutter--mobile;
  margin-left: auto;
  margin-right: auto;
}

.container--lg {
  max-width: $container--lg__max-width;
}

.container--narrow {
  max-width: $container--narrow__max-width;
}

.col--flex {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .container {
    padding-left: $container__gutter--desktop;
    padding-right: $container__gutter--desktop;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .container {
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }
}