.related-grants {
  @include padding-spacer(14, top);
  @include padding-spacer(14, bottom);
}

.related-grants__list {
  li {
    margin: 0;
    padding: 0;
    @include margin-spacer(6, bottom);

    &:before {
      display: none;
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  img {
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1.6rem;
  }

  span {
    flex-grow: 1;
  }
}