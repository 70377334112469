.site-footer {
  border-top: 2px solid $grey3;
}

.pre-footer {
  background-color: $white;
  @include padding-spacer(12, top);
  @include padding-spacer(12, bottom);
}

.main-footer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $foundation-green;
  color: $cloud-white;
  @include padding-spacer(14, top);
  @include padding-spacer(14, bottom);
  text-align: center;

  a {
    color: inherit;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul {
    margin: 0;
  }

  li {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.main-footer__contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include padding-spacer(10, bottom);
  @include margin-spacer(10, bottom);
  border-bottom: 4px dotted $southwark-mint;

  strong {
    margin-bottom: .4rem;
  }
}

.main-footer__email-address {
  margin: .4rem 0 .8rem;
}

.footer-logos {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: auto;
    height: 40px;
    margin: 0 .8rem;
  }
}

ul.main-footer__social-links {
  @include margin-spacer(4, top);
  display: flex;

  li {
    margin: 0 .8rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    background-color: $cloud-white;

    svg {
      display: block;
      height: auto;
      max-width: 1.4rem;
      color: $foundation-green;
    }

    &:hover {
      background-color: $grey3;
    }
  }
}

.main-footer__nav {
  li {
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-footer__legal {
  @include margin-spacer(10, top);
  max-width: 256px;

  .logo {
    display: block;
    @include margin-spacer(10, top);
    @include margin-spacer(7, bottom);
  }
}

.main-footer__wj {
  margin-top: map-get($spacers--mobile, 5);
}

.main-footer {
  .footer-shape {
    display: none;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .footer-logos img {
    height: 56px;
    margin: 0 1.2rem;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .footer-logos img {
    height: 80px;
    margin: 0 1.6rem;
  }

  .main-footer__legal {
    &.body-sm {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .main-footer__wj {
    &.body-sm {
      font-size: 18px;
      line-height: 24px;
    }
  }

  ul.main-footer__social-links {
    a {
      width: 4rem;
      height: 4rem;

      svg {
        max-width: 2rem;
      }
      .main-footer__social-links__linkedin-icon {
        width: 1.8rem;
        height: auto;
        margin-top: -1px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .footer-logos img {
    height: 104px;
  }

  .main-footer {
    position: relative;
    overflow: hidden;

    .footer-shape {
      display: block;
      position: absolute;
      bottom: 0;
      width: 275px;

      polygon {
        fill: $southwark-mint;
      }

      &.shape-1 {
        left: -100px;
      }
      &.shape-2 {
        right: -100px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .main-footer {
    .footer-shape {
      &.shape-1 {
        left: 0;
      }
      &.shape-2 {
        right: 0;
      }
    }
  }
}